import React from 'react';
import { useNavigate } from 'react-router-dom';

import useAreAllPlaylistsPaused from '@/hooks/playlists/useAreAllPlaylistsPaused';

import ChartCard from '../components/cards/ChartCard';
import PendingPitchesCard from '../components/cards/PendingPitchesCard';
import PlaylistStatsCard from '../components/cards/PlaylistStatsCard';
import ToDo from '../components/cards/ToDo';
import TotalBalanceCard from '../components/cards/TotalBalanceCard';
import UserHeader from '../components/utility/navigation/UserHeader';

export default function HomePage() {
  document.title = 'Dashboard';
  const navigate = useNavigate();
  const { areAllPlaylistsPaused, areAllPlaylistsPausedIsLoading } = useAreAllPlaylistsPaused();

  return (
    <div className="page-content">
      <UserHeader isAddPlaylist={true} />
      <ToDo />
      {!areAllPlaylistsPausedIsLoading && areAllPlaylistsPaused?.allPaused && (
        <div
          className="to-do-card-border mt16 cursor-pointer"
          onClick={() => {
            navigate('/settings');
          }}
        >
          <div className="text-center title-card to-do-card">
            <h4 data-testid="holiday-mode">
              Turn off holiday mode 🏝️ to start receiving pitches to your playlists again
            </h4>
          </div>
        </div>
      )}
      <div className="d-flex gap16 mt16">
        <TotalBalanceCard navButton={true} />
        <PendingPitchesCard />
      </div>
      <div className="d-flex gap16 mt16">
        <PlaylistStatsCard />
        <ChartCard />
      </div>
    </div>
  );
}
